<template>
  <div class="wrapper">
    <header v-if="header">
      <h1><span>Kontakt</span> - Napisz do nas</h1>
    </header>
    <form @submit.prevent="onSubmitForm">
      <div class="form-group">
        <div class="form-control">
          <input
            type="text"
            id="name"
            v-model="formData.name"
            placeholder="Imię i nazwisko"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="form-control mr">
          <input
            type="text"
            id="phone"
            name="phone"
            v-model="formData.phone"
            placeholder="+48 600 000 000"
          />
        </div>
        <div class="form-control">
          <input
            type="text"
            id="mail"
            name="mail"
            v-model="formData.mail"
            placeholder="pso@najem.wroc.pl"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <input
            type="text"
            id="subject"
            name="subject"
            v-model="formData.subject"
            placeholder="Wpisz Temat"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="form-control checkbox">
          <input
            type="checkbox"
            id="extended"
            name="extended"
            v-model="formData.extendedForm"
          />
          <label for="extended"
            >Czy chcesz podać dane swojej nieruchomości?</label
          >
        </div>
      </div>
      <div class="extended-form" v-if="formData.extendedForm">
        <div class="form-group">
          <div class="form-control">
            <input
              type="text"
              id="apartmentsQuantity"
              name="apartmentsQuantity"
              v-model="formData.apartmentsQuantity"
              placeholder="Liczba mieszkań na wynajem"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="form-control">
            <input
              type="text"
              id="surface"
              name="surface"
              v-model="formData.surface"
              placeholder="Powierzchnia mieszkania"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="form-control">
            <input
              type="text"
              id="rooms"
              name="rooms"
              v-model="formData.rooms"
              placeholder="Układ mieszkania - liczba pokoi"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="form-control">
            <input
              type="text"
              id="prefContactHours"
              name="prefContactHours"
              v-model="formData.prefContactHours"
              placeholder="Preferowane godziny kontaktu"
            />
          </div>
        </div>
        <h4>
          Czy oprócz zarządzania najmem, chcieliby Państwo skorzystać z innych
          usług?
        </h4>
        <div class="check-group">
          <div>
            <input
              type="checkbox"
              id="consulting"
              name="consulting"
              v-model="formData.consulting"
              placeholder="Pozostałe usługi"
            />
            <label for="consulting"
              >Konsultacja w zakresie wykończenia i wyposażenia lokalu
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="renovation"
              name="renovation"
              v-model="formData.renovation"
              placeholder="Pozostałe usługi"
            />
            <label for="renovation"
              >Wykonanie aranżacji, zakup i montaż mebli oraz AGD/RTV
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="cleaning"
              name="cleaning"
              v-model="formData.cleaning"
              placeholder="Pozostałe usługi"
            />
            <label for="cleaning">Usługi sprzątania lokalu </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="insurance"
              name="insurance"
              v-model="formData.insurance"
              placeholder="Pozostałe usługi"
            />
            <label for="insurance">Doradztwo ubezpieczeniowe </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="tax"
              name="tax"
              v-model="formData.tax"
              placeholder="Pozostałe usługi"
            />
            <label for="tax">Rozliczanie kwestii podatkowych </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="investment"
              name="investment"
              v-model="formData.investment"
              placeholder="Pozostałe usługi"
            />
            <label for="investment"
              >Konsultacje na temat następnych inwestycji / Doradztwo kredytowe
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="formData.msg"
          ></textarea>
        </div>
      </div>
      <div class="res" :class="{ valid: isValid }" v-if="hasMsg">
        <div v-for="msg in resMsgs" :key="msg">
          {{ msg.msg }}
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <button type="submit" class="btn base">Wyślij</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["header", "mailParams"],
  data() {
    return {
      hasMsg: false,
      resMsgs: [],
      isValid: true,
      formData: {
        name: "",
        phone: "",
        mail: "",
        subject: "",
        extendedForm: false,
        apartmentsQuantity: "",
        surface: "",
        rooms: "",
        prefContactHours: "",
        consulting: false,
        renovation: false,
        cleaning: false,
        insurance: false,
        tax: false,
        investment: false,
        msg: this.mailParams ? this.mailParams.msg : "Wpisz wiadomość",
      },
      initFormData: {
        name: "",
        phone: "",
        mail: "",
        subject: "",
        extendedForm: false,
        apartmentsQuantity: "",
        surface: "",
        rooms: "",
        prefContactHours: "",
        consulting: false,
        renovation: false,
        cleaning: false,
        insurance: false,
        tax: false,
        investment: false,
        msg: this.mailParams ? this.mailParams.msg : "Wpisz wiadomość",
      },
    };
  },
  methods: {
    async onSubmitForm() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/send`,
          this.formData
        );
        this.hasMsg = true;
        this.isValid = true;
        this.formData = this.initFormData;
        this.resMsgs = [];
        this.resMsgs.push(response.data);
        this.formData = this.initFormData;
      } catch (err) {
        this.isValid = false;
        this.hasMsg = true;
        this.resMsgs = err.response.data.errors;
      }
    },
  },
};
</script>

<style lang ="scss" scoped>
@import "~@/_base.scss";
.res {
  margin-bottom: 1rem;
  color: red;
  &.valid {
    color: green;
  }
}
.wrapper {
  width: auto;
  max-width: 960px;
  width: 90%;
  header {
    margin-top: 3rem;
    h1 {
      font-weight: normal;
      span {
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 8px solid $primary;
      }
    }
  }
  .check-group {
    flex-direction: column;
    border: none;
    margin-bottom: 2rem;
    div {
      margin-bottom: 1rem;
    }
  }
  .form-group {
    display: flex;
    width: 100%;

    .form-control {
      width: 100%;
      margin-bottom: 1rem;
      input,
      select,
      textarea {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        color: $fontLight;
      }
      &.checkbox {
        display: flex;
        align-items: center;
        input {
          width: auto;
        }
      }
      textarea {
        resize: none;
        font-family: inherit;
      }
      &.mr {
        margin-right: 1rem;
      }
    }
  }
}
</style>