<template>
  <section class="">
    <tool-item v-for="tool in tools" :tool="tool" :key="tool.id"></tool-item>
  </section>
</template>

<script>
import ToolItem from "./ToolItem.vue";

export default {
  components: { ToolItem },
  props: ["tools"],
};
</script>

<style lang='scss' scoped>
@import "~@/_base.scss";

section {
  margin-top: 4em;
  display: grid;
  grid-template-columns: 1fr;
}
</style>