<template>
  <div class="item" data-aos="fade-right">
    <div class="img">
      <component :is="tool.img"></component>
    </div>
    <div class="item-content">
      <h3>
        {{ tool.mainCapitalize }} <span>{{ tool.mainLower }}</span>
      </h3>
      <p v-if="tool.text">{{ tool.text }}</p>
      <div v-if="tool.input">
        <input type="text" placeholder="Szukaj..." />
      </div>
    </div>
  </div>
</template>

<script>
import Roof from "./Roof.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  props: ["tool"],
  components: { Roof },
  methods: {
    navigate(dest) {
      this.$router.push({ name: dest });
    },
  },
  mounted() {
    AOS.init({
      delay: 100,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/_base.scss";

.item {
  display: flex;
  color: $fontLight;
  padding: 0 1rem;
  font-size: 1.5rem;
  width: 100%;
  justify-content: space-between;
  background: $primary;
  padding: 3rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  border-radius: 4px;
  box-shadow: $light;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  position: relative;

  border-top: 12px solid white;
  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
    left: unset;

    background: white;
    color: $primary;
    border-top: 12px solid $primary;
    text-shadow: unset;
    color: $fontDark;
  }
  input {
    width: 100%;
    border: none;
    box-shadow: $light;
    font-size: $baseSize;
    border-radius: 4px;
    padding: 0.5rem;
  }
  .item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 70%;
  }
  h3 {
    text-transform: uppercase;
    span {
      text-transform: capitalize;
      font-weight: normal;
    }
  }
  .img {
    width: 15rem;
    height: 15rem;
    background: $primary;
    border-radius: 50%;
    border: 10px solid #fff;
    box-shadow: $light;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    svg {
      fill: white;
      width: 70%;
      filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.2));
    }
  }
  .extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .item {
    align-items: center;
    .img {
      width: 10rem;
      height: 10rem;
    }
  }
}
@media screen and (max-width: 980px) {
  .item {
    align-items: center;
    font-size: 1rem;
    flex-direction: column;
    padding: 5%;
    &:nth-child(2n + 1) {
      flex-direction: column-reverse;
      padding: 5%;
      .img {
        margin-top: 2rem;
      }
    }
    .item-content {
      max-width: 90%;
    }
    .img {
      width: 10rem;
      height: 10rem;
    }
  }
}
</style>