export const mainServices = [
  {
    id: 0,
    img: "roof",
    alt: "Forum",
    mainCapitalize: "ZARZĄDZANIE",
    mainLower: "NIERUCHOMOŚCIĄ WSPÓLNĄ",
    input: false,
    text:
      "Zajmujemy się zarządzaniem nieruchomością wspólną, w której znajduje się Twoje mieszkanie. Dzięki temu mamy kontrolę nad jej faktycznym stanem i kompleksowo reagujemy na wszelkie kwestie.",
    button: {
      extra: "",
      text: "Sprawdź",
    },
    dest: "Management",
  },
  {
    id: 1,
    img: "broom",
    alt: "Forum",
    mainCapitalize: "PRZYGOTOWANIE",
    input: false,
    mainLower: "MIESZKANIA NA WYNAJEM",
    text:
      "Weryfikujemy stan lokalu przed jego wynajmem, organizujemy sprzątanie, zapewniamy sesje fotograficzne i przygotowujemy ogłoszenia na portale internetowe dotyczące nieruchomości.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "people-arrows",
    alt: "Forum",
    mainCapitalize: "ZNALEZIENIE NAJEMCY",
    input: false,
    mainLower: "I PRZEPROWADZENIE WERYFIKACJI",
    text:
      "Nasz pakiet obejmuje nielimitowane prezentacje lokalu zainteresowanym do czasu znalezienia najemcy. Przeprowadzamy weryfikacje finansowe potencjalnych najemców aby zapewnić Cię, że Twój lokal będzie przynosił stałe dochody.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "agreement",
    alt: "Forum",
    mainCapitalize: "REPREZENTOWANIE",
    input: false,
    mainLower: "PODCZAS PODPISYWANIA UMÓW NAJMU",
    text:
      "Nie musisz dostosowywać swojego grafiku do najemcy. Zawieramy umowę najmu w Twoim imieniu i przekazujemy lokal wraz ze sporządzeniem dokładnego protokołu zdawczo-odbiorczego. Ponadto zorganizujemy zawarcie przez najemcę umowy obowiązkowego ubezpieczenia OC.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "hands",
    alt: "Forum",
    mainCapitalize: "OPIEKA NAD NAJEMCĄ",
    input: false,
    mainLower: "WE WSZYSTKICH SPRAWACH LOKALU",
    text:
      "Jeżeli Twój najemca ma jakikolwiek problem związany z mieszkaniem, nie musisz zajmować się jego rozwiązywaniem. Wszelkie kwestie załatwiamy w Twoim imieniu po uprzedniej konsultacji.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "money",
    alt: "Forum",
    mainCapitalize: "KONTROLA PŁATNOŚCI",
    input: false,
    mainLower: "I ROZLICZANIE MEDIÓW",
    text:
      "Na bieżąco kontrolujemy płatności związane z Twoim lokalem. W razie konieczności przeprowadzamy windykację najemcy. Ponadto odpowiadamy za rozliczenia mediów dostarczonych do lokalu, wykonujemy wszystkie przelewy do dostawców i wspólnoty mieszkaniowej.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "crack",
    alt: "Forum",
    mainCapitalize: "KONTROLA",
    input: false,
    mainLower: "STANU TECHNICZNEGO",
    text:
      "Przeprowadzamy regularne kontrole stanu technicznego lokalu i jego zużycia. Sprawdzamy stan wyposażenia i urządzeń znajdujących się w mieszkaniu, dzięki czemu na bieżąco wiesz, co dzieje się w lokalu w trakcie wynajmu.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "tools",
    alt: "Forum",
    mainCapitalize: "ZLECENIA NAPRAW",
    input: false,
    mainLower: "USTEREK, NADZÓR NAD ICH WYKONANIEM",
    text:
      "Jeżeli w Twoim lokalu wystąpi usterka, awaria lub jakiś element wyposażenia będzie wymagał wymiany, nasz wykwalifikowany serwis techniczny zapewni możliwie najszybsze rozwiązanie problemu.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
];
