import { createStore } from "vuex";
import axios from "axios";
import router from "../router/index";
import { toast } from "vue3-toastify";

export default createStore({
  state: {
    apartments: [],
    activeApartment: {},
    token: "",
  },
  mutations: {
    setAllApartments(state, payload) {
      state.apartments = [...payload];
    },
    setSingleApartment(state, payload) {
      state.apartment = { ...payload };
    },
    setToken(state, payload) {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    logout(state) {
      toast.error("Token wygasł, zostałeś wylogowany");
      localStorage.removeItem("token");
      state.token = null;
      router.push("/admin");
    },
  },
  getters: {
    getAllApartments(state) {
      return state.apartments;
    },
    getApartmentById: (state) => (id) => {
      return state.apartments.find((ap) => {
        return ap.id == id;
      });
    },
    getToken(state) {
      return state.token || localStorage.getItem("token");
    },
    isLoggedIn(state) {
      return !!state.token || !!localStorage.getItem("token");
    },
  },
  actions: {
    async fetchAllApartments(context) {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/apartments`)
        .then((res) => {
          context.commit("setAllApartments", res.data);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            toast.error("Nieprawidłowy token.");
            this.logout();
          }
        });
    },
    async fetchSingleApartment(context, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/apartments/${payload}`
        );
        context.commit("setSingleApartment", res.data);
        return res.data;
      } catch (err) {
        if (err.response.status === 403 || err.response.status === 401) {
          toast.error("Nieprawidłowy token.");
          this.logout();
        }
      }
    },
    setToken(context, payload) {
      context.commit("setToken", payload);
    },
    logout(context) {
      context.commit("logout");
    },
  },
  modules: {},
});
