<template>
  <div class="item">
    <div
      class="pic"
      :style="{
        backgroundImage: `url(${getImgUrl(
          apartment.apartmentBackgrounds[0]?.fileName,
          apartment.apartmentBackgrounds[0]?.path
        )})`,
      }"
    ></div>
    <div class="desc">
      <header>
        <h2>{{ apartment.title }}</h2>
        <h4>{{ apartment.city }}, {{ apartment.district }}</h4>
      </header>
      <div class="content">
        <div>
          <div>
            Pokoje: <span>{{ apartment.rooms }}</span>
          </div>
          <div>
            Powierzchnia: <span>{{ apartment.surface }}</span>
          </div>
        </div>
        <div>
          <div>
            Koszt: <span>{{ apartment.price }}</span> PLN / mies
          </div>
          <div class="nav">
            <router-link class="btn outline" to="/mieszkania"
              >Przejdź</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["apartment"],
  methods: {
    getImgUrl(pic, path) {
      if (!pic || !path) {
        return "";
      }
      return `${process.env.VUE_APP_API_URL}/${path}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/_base.scss";
.item {
  padding: 1rem;
  cursor: pointer;
  &:hover {
    header {
      text-decoration: underline;
    }
    .outline {
      background: $primary;
      color: white;
      text-decoration: none;
    }
  }
  display: flex;
  flex-direction: row;
  border-bottom: $black;
  padding-bottom: 2rem;
  padding-top: 2rem;
  min-height: 160px;
  &:first-child {
    padding-top: 1rem;
  }
  &:last-child {
    border-bottom: 0;
  }
  .desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h2 {
    margin-bottom: 0;
    font-weight: normal;
    margin-top: 0;
  }
  h4 {
    margin-top: 0;
    color: $fontLight;
    font-weight: normal;
  }
  .content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: $fontLight;
    width: 100%;
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    span {
      color: $fontDark;
      font-weight: bold;
    }
    .nav {
      color: $primary;
      font-weight: bold;
      text-align: end;
      font-size: $baseSize * 1.5;
      margin-top: 1rem;
    }
  }
  .pic {
    width: 45%;
    margin-right: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media screen and(max-width: 980px) {
  .item {
    flex-direction: column;

    .pic {
      width: 100%;
      height: 150px;
      margin-bottom: 1rem;
    }
    .desc {
      header {
        h2,
        h4 {
          font-size: 1rem;
        }
      }
      font-size: 1rem;
      .content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        div {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 1rem;
          font-size: 0.9rem;
          > div {
            margin-top: 0;
            &:last-child {
              text-align: right;
            }
          }

          .nav {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>