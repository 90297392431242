<template>
  <div class="card" :class="{ border: isBorder }" :style="{ width: perc }">
    <div class="content"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    isBorder: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    perc() {
      return (this.columns / 12) * 100 + "%";
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/_base.scss";

.card {
  display: flex;
  height: auto;

  //width: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 2px 15px rgb(0 0 0 / 15%),
    0 1px 1px rgb(0 0 0 / 10%);
  align-items: flex-start;
  &.border {
    border-top: 12px solid $primary;
  }
  .content {
    width: 100%;
  }
}
</style>