<template>
  <div class="wrapper partner-container">
    <header>
      <h1><span>Sponsorujemy</span> - Wspieramy Kulturę</h1>
    </header>
    <p>
      Jesteśmy sponsorem Narodowego Forum Muzyki (NFM) - Instytucji kultury
      miasta Wrocław oraz klubu piłkarskiego Parasol Wrocław
    </p>
    <div class="partners-img">
      <div class="baner" @click="navigate('https://www.nfm.wroclaw.pl/')">
        <img
          src="https://www.forum.wroc.pl/wp-content/themes/forum/assets/images/Logo/NFM_900.png"
          alt="partner-img"
        />
      </div>
      <div class="baner" @click="navigate('https://parasol.wroclaw.pl/')">
        <img src="https://parasol.wroclaw.pl/img/logo.png" alt="partner-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navigate(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang ="scss" scoped>
@import "~@/_base.scss";
.partner-container {
  position: relative;
  z-index: 3;

  &.wrapper {
    border-bottom: $black;
    padding-bottom: 3rem;
    header {
      padding: 0.8rem 1rem;
      border-radius: 4px;
      h1 {
        font-weight: normal;
        span {
          font-weight: bold;
          text-transform: uppercase;
          border-bottom: 8px solid $primary;
        }
      }
    }
    p {
      max-width: 60%;
    }
    .partners-img {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      div {
        max-width: 100%;
        &.baner {
          cursor: pointer;
        }
        img {
          width: 100%;
          height: 120px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .partner-container {
    &.wrapper {
      .partners-img {
        flex-direction: column;
        div {
          max-width: 100%;
          margin-bottom: 2rem;
          img {
            height: auto;
          }
          &:last-child {
            display: flex;
            justify-content: center;
            img {
              width: 20%;
            }
          }
        }
      }
    }
  }
}
</style>