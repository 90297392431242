<template>
  <div v-if="getApartments.length > 0 && text === false">
    <apartment-item
      @click="navigateToApartment(apartment.id)"
      v-for="apartment in getApartments"
      :key="apartment.id"
      :apartment="apartment"
    ></apartment-item>
  </div>
  <div class="p" v-else>
    <h3>Czym jest zarządzanie najmem?</h3>
    <p>
      Jesteś lub będziesz właścicielem mieszkania, apartamentu lub lokalu
      użytkowego i chcesz czerpać z nieruchomości zyski, a nie masz czasu lub
      wiedzy jak sobie z tym poradzić? Proponujemy idealne rozwiązanie:
      zarządzanie najmem.
    </p>
    <p>
      To unikalna, kompleksowa oferta, w której zabezpieczysz się przed
      nieuczciwymi najemcami, a Twoja nieruchomość będzie przynosiła stałe
      dochody i zostanie utrzymana w najlepszym standardzie
    </p>
    <p>
      Dzięki temu Twoje mieszkanie będzie utrzymywało wysoką wartość i
      pozostanie atrakcyjne na rynku wynajmu.
    </p>
    <h3>Kim jesteśmy?</h3>
    <p>
      Nieruchomościami zajmujemy się od ponad 20 lat. Naszą główną gałęzią
      działalności jest zarządzanie wspólnotami mieszkaniowymi, co pozwoliło nam
      uzyskać rekordową liczbę 6000 tysięcy klientów. Odpowiadając jednak na
      potrzeby rynku i właścicieli mieszkań w naszych budynkach stworzyliśmy
      dział najmu, który w zaledwie trzy lata objął w zarządzanie ponad 500
      lokali na wynajem. Jest to na rynku dolnośląskim unikalne połączenie,
      gdzie jeden zarządca opiekuje się zarówno mieszkaniami, jak i
      nieruchomością wspólną. To pozwala nam na utrzymanie najwyższego standardu
      usług, co przekłada się na rosnące zadowolenie naszych klientów.
      Doświadczenie w obsłudze mieszkań na wynajem pozwoliło nam na pozyskanie
      klientów także spoza budynków, którymi zarządzamy i na których jesteśmy
      nieprzerwanie otwarci.
    </p>
  </div>
</template>

<script>
import ApartmentItem from "./ApartmentItem.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    limit: Number,
    apartmentsList: Object,
    text: {
      type: Boolean,
      default: false,
    },
  },
  components: { ApartmentItem },
  computed: {
    getApartments() {
      return this.apartmentsList.slice(0, this.limit);
    },
    ...mapGetters(["getAllApartments"]),
  },
  methods: {
    navigateToApartment(id) {
      this.$router.push(`/mieszkania/${id}`);
    },
    ...mapActions(["fetchAllApartments"]),
  },
  async mounted() {
    await this.fetchAllApartments();
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";
.p {
  font-size: 1.3rem;
  h3 {
    &:last-of-type {
      padding-top: 1rem;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
}
@media screen and (max-width: 640px) {
  .p {
    padding: 2%;
  }
}
</style>