<template>
  <header>
    <div class="wrapper">
      <the-nav></the-nav>
      <!-- <search-bar></search-bar> -->
    </div>
  </header>
</template>

<script>
//import SearchBar from "../search/SearchBar.vue";
import TheNav from "./TheNav.vue";
export default {
  components: { TheNav },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  height: 350px;
  width: 100%;
  background-image: linear-gradient(
      0deg,
      black -25%,
      rgba(0, 0, 0, 0) 50%,
      black 130%
    ),
    url(/img/fn_najem_bg.c2c286a5.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 20px;
}
</style>