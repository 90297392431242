import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BaseCard from "./components/base/BaseCard";
import Roof from "./components/tools/Roof";
import Conversation from "./components/tools/Conversation";
import Arrow from "./components/management/Arrow";
import Broom from "./components/tools/Broom";
import PeopleArrows from "./components/tools/PeopleArrows";
import Agreement from "./components/tools/Agreement";
import Hands from "./components/tools/Hands";
import Money from "./components/tools/Money";
import Tools from "./components/tools/Tools";
import Crack from "./components/tools/Crack";
import Fire from "./components/tools/Fire";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";

createApp(App)
  .use(store)
  .use(router)
  .use(Vue3Toastify, {
    autoClose: 5000,
  })
  .component("base-card", BaseCard)
  .component("conversation", Conversation)
  .component("roof", Roof)
  .component("broom", Broom)
  .component("arrow", Arrow)
  .component("people-arrows", PeopleArrows)
  .component("agreement", Agreement)
  .component("hands", Hands)
  .component("money", Money)
  .component("tools", Tools)
  .component("crack", Crack)
  .component("fire", Fire)
  .mount("#app");
