<template>
  <div class="fh">
    <the-header> </the-header>
    <router-view />
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "./components/UI/TheFooter.vue";
import TheHeader from "./components/UI/TheHeader.vue";
export default {
  components: { TheHeader, TheFooter },
};
</script>

<style lang="scss">
@import "~@/base.scss";

body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Roboto, sans-serif;
  color: $fontDark;
}
.fh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
