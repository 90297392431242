<template>
  <div class="wrapper partner-container">
    <header>
      <h1><span>Partnerzy</span> - Współpracujemy</h1>
    </header>
    <p>
      Wychodząc Państwu naprzeciw, chcąc uczynić naszą ofertę kompleksową,
      podjeliśmy stałą współpracę m.in. z:
    </p>
    <div class="partners-img">
      <div>
        <img
          src="https://www.forum.wroc.pl/wp-content/uploads/2021/06/SHARE_P_LOGO-440x1601-1.png"
          alt="partner-img"
        />
      </div>
      <div>
        <img
          src="https://www.forum.wroc.pl/wp-content/uploads/2021/06/baner-mieszkanie-SM-268x64-14zl-new.gif"
          alt="partner-img"
        />
      </div>
      <div>
        <img
          src="https://www.forum.wroc.pl/wp-content/themes/forum/assets/images/Logo/03_E-kartoteka_logo.png"
          alt="partner-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang ="scss" scoped>
@import "~@/_base.scss";
.partner-container {
  position: relative;
  z-index: 3;

  &.wrapper {
    padding-bottom: 3rem;
    header {
      padding: 0.8rem 1rem;
      border-top: 8px solid $primary;
      border-radius: 4px;
      h1 {
        font-weight: normal;
        span {
          font-weight: bold;
          text-transform: uppercase;
          border-bottom: 8px solid $primary;
        }
      }
    }
    p {
      max-width: 60%;
    }
    .partners-img {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      div {
        max-width: 30%;
        img {
          width: 100%;
          height: 80px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 4px;
        }
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .partner-container {
    &.wrapper {
      .partners-img {
        flex-direction: column;
        div {
          max-width: 100%;
          margin-bottom: 2rem;
          img {
            height: auto;
          }
        }
      }
    }
  }
}
</style>