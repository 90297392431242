export const subServices = [
  {
    id: 1,
    img: "conversation",
    alt: "Forum",
    mainCapitalize: "KONSULTACJE",
    input: false,
    mainLower: "W ZAKRESIE WYPOSAŻENIA I ARANŻACJI LOKALU",
    text:
      "Jeżeli chcesz dowiedzieć się, w co warto wyposażyć mieszkanie pod wynajem, jakie rozwiązania warto zastosować a jakich unikać – nasi konsultanci z chęcią pomogą w doborze formy wykończenia i odpowiedniego wyposażenia.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "tools",
    alt: "Forum",
    mainCapitalize: "WYKONANIE ARANŻACJI,",
    input: false,
    mainLower: "MONTAŻ MEBLI I SPRZĘTÓW AGD/RTV",
    text:
      "Nasz dział techniczny na Twoje życzenie może wykonać montaż mebli stanowiących wyposażenie lokalu, kuchni, a także podłączy sprzęty AGD i RTV. Ponadto nasi konsultanci pomogą Ci wyposażyć mieszkanie w niezbędne przedmioty, dzięki czemu oszczędzisz swój czas na zakupach.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "broom",
    alt: "Forum",
    mainCapitalize: "USŁUGI",
    input: false,
    mainLower: "SPRZĄTANIA LOKALU",
    text:
      "Korzystamy z usług profesjonalnych firm sprzątających. Na życzenie możemy zorganizować jednorazowe, cykliczne lub regularne sprzątanie w Twoim mieszkaniu na wynajem.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "fire",
    alt: "Forum",
    mainCapitalize: "DORADZTWO",
    input: false,
    mainLower: "UBEZPIECZENIOWE",
    text:
      "Podstawą wynajmu mieszkania jest jego dobre ubezpieczenie. Nasi zaprzyjaźnieni agenci pomogą Ci dobrać odpowiednią polisę, która zabezpieczy Cię przed ewentualnymi szkodami lub stratami.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
  {
    id: 1,
    img: "money",
    alt: "Forum",
    mainCapitalize: "POMOC W ROZLICZANIU",
    input: false,
    mainLower: "PODATKÓW Z NAJMU",
    text:
      "Korzystamy z wykwalifikowanych biur rachunkowych, którzy chętnie pomogą Ci rozliczyć najem Twojego mieszkania.",
    button: {
      extra: "",
      text: "Przejdź",
    },
    dest: "Consulting",
  },
];
