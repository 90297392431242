<template>
  <div class="">
    <header class="wrapper move-up">
      <div class="hflex">
        <h2>Najem - <span>Witaj w usłudze zarządzania najmem</span></h2>
        <button class="btn base" @click="scrollToApartments">
          Zobacz mieszkania
        </button>
      </div>
    </header>
    <section class="wrapper">
      <base-card class="" :columns="12">
        <template #default>
          <apartments-list
            :apartmentsList="getAllApartments"
            :limit="2"
            :text="true"
          ></apartments-list>
        </template>
      </base-card>
    </section>
    <header class="wrapper mid">
      <div>
        <h2>
          Najem -
          <span>Na naszą stałą usługę zarządzania najmem składają się</span>
        </h2>
      </div>
    </header>
    <tools-grid :tools="mainServices" class="wrapper py"></tools-grid>
    <header class="wrapper mid">
      <div>
        <h2>
          Najem -
          <span
            >Ponadto w naszej ofercie znajdziesz także usługi dodatkowe</span
          >
        </h2>
      </div>
    </header>
    <tools-grid :tools="subServices" class="wrapper py"></tools-grid>
    <section class="wrapper search">
      <header ref="scrollToMe">
        <div>
          <h2 class="apartments-header">
            <span>Zapoznaj się z lokalami</span>, które już dziś są w naszej
            ofercie do wynajęcia:
          </h2>
        </div>
      </header>
      <!-- <search-bar></search-bar> -->
      <base-card class="mt-4" :columns="12">
        <apartments-list
          :apartmentsList="apartments"
          :limit="2"
          :text="false"
        ></apartments-list
      ></base-card>
      <div class="all-apartments">
        <button class="btn base" @click="navigateToAllApartments()">
          Zobacz wszystkie mieszkania
        </button>
      </div>
    </section>
    <section class="about">
      <div class="wrapper">
        <h2>Forum - <span>Zarządzanie najmem</span></h2>
        <div class="grid-boxes">
          <div class="left-grid">
            <div class="grid-box">
              <div class="years">20</div>
              <div>lat na rynku<br />nieruchomości</div>
            </div>
            <div class="grid-box">
              <div class="objects">24</div>
              <div>godziny<br />dostępności</div>
            </div>
            <div class="grid-box">
              <div class="clients">6000</div>
              <div>zadowolonych<br />klientów</div>
            </div>
            <div class="grid-box">
              <div class="money">500</div>
              <div>lokali<br />w zarządzaniu najmem</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="move-up-slightly">
      <partners-section></partners-section>
    </section>
    <section class="move-up-slightly">
      <sponsors-section></sponsors-section>
    </section>
    <section class="move-up-slightly">
      <main-contact :header="true"></main-contact>
    </section>
  </div>
</template>

<script>
import ApartmentsList from "../components/apartments/ApartmentsList.vue";
import PartnersSection from "../components/partners/PartnersSection.vue";
import SponsorsSection from "../components/partners/SponsorsSection.vue";
import ToolsGrid from "../components/tools/ToolsGrid.vue";
import MainContact from "../components/contact/MainContact.vue";
// import SearchBar from "../components/search/SearchBar.vue";
import { mainServices } from "../components/tools/mainServices";
import { subServices } from "../components/tools/subServices";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ApartmentsList,
    ToolsGrid,
    PartnersSection,
    MainContact,
    // SearchBar,
    SponsorsSection,
  },
  data() {
    return {
      apartments: [],
    };
  },
  computed: {
    ...mapGetters(["getAllApartments"]),
    mainServices() {
      return mainServices;
    },
    subServices() {
      return subServices;
    },
  },
  methods: {
    ...mapActions(["fetchAllApartments"]),
    navigateToAllApartments() {
      this.$router.push({ name: "Apartments" });
    },
    scrollToApartments() {
      const el = this.$refs.scrollToMe;

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  async mounted() {
    await this.fetchAllApartments();
    this.apartments = this.getAllApartments;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/_base.scss";
.hflex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.all-apartments {
  margin-top: 1rem;
  text-align: right;
}
header {
  text-shadow: $multi;

  h2 {
    text-transform: uppercase;
    font-size: 1.75em;
    color: $primary;
    span {
      text-transform: none;
      font-size: 0.95em;
      font-weight: normal;
      color: white;
    }
  }
  &.mid {
    text-shadow: none;
    font-size: 150%;
    padding-top: 1rem;
    h2 {
      span {
        color: $fontDark;
      }
    }
  }
  .apartments-header {
    color: $fontDark;
    text-shadow: none;
    margin-bottom: 0;
    span {
      color: $primary;
      text-transform: uppercase;
      font-weight: bold;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
}

.search {
  padding-bottom: 2rem;
}
.mt-4 {
  margin-top: 2rem;
}
.about {
  position: relative;
  z-index: 1;
  background: url("https://www.forum.wroc.pl/wp-content/themes/forum/assets/images/bg/yellow_center_fixed.jpg")
    center center fixed;
  background-size: cover;
  padding-top: 80px;
  font-size: 18px;
  text-align: center;
  text-shadow: 1px 1px 1px #fff, 1px 0 10px #fff;
  padding-bottom: 160px;
  box-shadow: inset 0 20px 60px -30px rgb(0 0 0 / 60%),
    inset 0 -20px 60px -30px rgb(0 0 0 / 60%);
  h2 {
    text-transform: uppercase;
    font-size: 1.75em;
    color: $fontLight;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: $fontLight;
    }
  }
  .grid-boxes {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    margin-top: 100px;
    margin-bottom: 20px;
    .left-grid {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      -ms-grid-column: 1;
    }

    .right-grid {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1fr 1fr;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-column: 2;
    }
  }
  .grid-boxes .grid-box div:first-child,
  .grid-boxes .right-grid .grid-box div:first-child {
    height: 55px;
    background: #fff;
    box-shadow: rgb(0 0 0 / 30%) 0 1px 1px;
    margin-right: 25px;
    margin-left: 25px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    font-size: 30px;
    position: relative;
    font-weight: 900;
  }
  .grid-boxes .grid-box div:first-child::after {
    position: absolute;
    z-index: 1;
    width: 15px;
    height: 15px;
    background: #fff;
    transform: rotate(45deg);
    content: " ";
    bottom: -7px;
    right: 44%;
    box-shadow: rgb(0 0 0 / 30%) 1px 1px 1px;
  }
  .grid-boxes .grid-box div:last-child {
    margin-top: 15px;
    text-shadow: none;
  }
}
.move-up-slightly {
  transform: translateY(-98px);
  position: relative;
  z-index: 5;
}
@media screen and (max-width: 980px) {
  .hflex {
    flex-direction: column;
    button {
      margin-bottom: 1rem;
    }
  }
  .about {
    .grid-boxes {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr;
      margin-top: 100px;
      margin-bottom: 20px;
      .left-grid {
        display: grid;
        display: -ms-grid;
        grid-template-columns: 1fr 1fr;
        -ms-grid-columns: 1fr 1fr;
        -ms-grid-column: 1;
        .grid-box {
          margin-bottom: 2rem;
        }
      }

      .right-grid {
        display: grid;
        display: -ms-grid;
        grid-template-columns: 1fr 1fr;
        -ms-grid-columns: 1fr 1fr;
        -ms-grid-column: 2;
      }
    }
  }
}
</style>