import { createRouter, createWebHistory } from "vue-router";
import SearchApp from "../views/SearchApp.vue";
import store from "../store";
import axios from "axios";
const routes = [
  {
    path: "/",
    name: "Home",
    component: SearchApp,
  },
  {
    path: "/mieszkania",
    name: "Apartments",
    component: () => import("../views/Apartments"),
    props: true,
  },
  {
    path: "/mieszkania/:id",
    name: "ApartmentDetails",
    component: () => import("../views/SingleApartment"),
  },
  {
    path: "/forum",
    name: "Forum",
    beforeEnter: () => {
      window.location.href = "https://forum.wroc.pl";
    },
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () => import("../views/Contact"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard"),
    meta: { requiresAuth: true },
  },
  {
    path: "/add",
    name: "Add",
    component: () => import("../views/ApartmentForm"),
    meta: { requiresAuth: true },
  },
  {
    path: "/edit/:id",
    name: "Edit",
    component: () => import("../views/ApartmentForm"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
    store.dispatch("logout");
    return {
      path: "/admin",
    };
  }
});

router.afterEach(async (to) => {
  if (to.meta.requiresAuth && store.getters.isLoggedIn) {
    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.VUE_APP_API_URL}/verify`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch(() => {
        store.dispatch("logout");
        return {
          path: "/admin",
        };
      });
  }
});

export default router;
