<template>
  <footer>
    <div class="wrapper">
      <div class="address-data">
        <header>
          <img
            src="@/assets/FORUM_logotype_white.svg"
            alt="forum-logo"
            class="forum-logo"
          />
          <h4><span>FORUM</span> Zarządzanie Nieruchomościami Sp. z o.o.</h4>
        </header>
        <div class="d-flex">
          <div class="flex-column">
            <div>Dla klientów Lokum Porto:</div>
            <div><span>Tel.:</span> +48 535 800 048</div>
            <div><span>Mail:</span> pso@najem.wroc.pl</div>
          </div>
          <div class="flex-column">
            <div>Dla klientów z innych nieruchomości:</div>
            <div><span>Tel.:</span> +48 537 503 763</div>
            <div><span>Mail:</span> biuro@najem.wroc.pl</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/_base.scss";

footer {
  border-top: 8px solid $primary;
  background: url("~@/assets/fn_najem_bg.jpeg") bottom fixed;
  padding-bottom: 4rem;
  margin-top: 2rem;
  .address-data {
    padding: 2rem 2rem;
    margin-bottom: 2rem;
    background: rgba(0, 0, 0, 0.6);
    width: 600px;
    margin: 0 auto;
    box-shadow: $light;
    color: white;
    header {
      display: flex;
      align-items: center;
      flex-direction: column;

      h4 {
        font-weight: normal;
        span {
          font-weight: bold;
        }
      }
    }
    .d-flex {
      display: flex;
      justify-content: space-between;
      .flex-column {
        flex-direction: column;
        align-items: center;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .forum-logo {
    width: 60%;
  }
  footer {
    .address-data {
      width: auto;
    }
    .d-flex {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .flex-column {
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
</style>