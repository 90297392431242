<template>
  <nav>
    <router-link to="/">
      <img
        src="@/assets/FORUM_logotype_white.svg"
        alt="forum-logo"
        class="forum-logo"
      />
    </router-link>
    <div class="flex">
      <ul class="sub">
        <li v-for="pos in subPositions" :key="pos.id" data-test="sub-menu-item">
          <router-link :to="pos.route">{{ pos.name }}</router-link>
        </li>
      </ul>
      <ul>
        <li
          v-for="pos in allMenuPositions"
          :key="pos.id"
          data-test="main-menu-item"
        >
          <router-link :to="pos.route">{{ pos.name }}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menuPositions: [
        { id: 0, route: "/", name: "Strona Główna" },
        { id: 2, route: "/mieszkania", name: "Mieszkania" },
        { id: 3, route: "/kontakt", name: "Kontakt" },
      ],
      subPositions: [
        { id: 1, route: "/forum", name: "Zarządzanie nieruchomościami" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    allMenuPositions() {
      return this.isLoggedIn
        ? [
            ...this.menuPositions,
            { id: 4, route: "/dashboard", name: "Dashboard" },
          ]
        : this.menuPositions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/_base.scss";
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: $white;
  margin-bottom: 40px;
  .flex {
    display: flex;
    flex-direction: column;
  }
  ul {
    display: flex;
    list-style: none;
    font-size: $baseSize;
    justify-content: flex-end;
    &.sub {
      font-size: $smallSize;
    }
    li {
      margin-left: 10px;
      font-weight: 900;
      color: #fff;
      text-shadow: 1px 1px 2px #131807, 1px 0 10px rgb(14 19 58 / 90%);
      text-transform: uppercase;
    }
  }
}
@media screen and (max-width: 1080px) {
  .forum-logo {
    width: 80%;
  }
  nav {
    ul {
      display: none;
      &.sub {
        display: none;
      }
    }
  }
}
</style>